import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from 'reactstrap';

import SelectModal from '../../components/SelectModal';
import ExportOptionsModal from '../../components/ExportOptionsModal';
import UsedCouponTable from '../../components/UsedCuponTable';

import { PageTitle, ContentPage } from '../_layouts/styles/common';

import { Col } from '../../styles/grid';

import {
  ActionsMenuBox,
  CouponsBox,
  SearchInput,
  SearchButton,
  SearchBox,
  SearchImage,
  TabsBox,
  TabBox,
  Tab,
  TabSelected,
} from './styles';

import { preto } from '../../styles/colors';

import {
  getUsedCouponsTraditionalRequest,
  getUsedCouponsFlashRequest,
} from '../../store/modules/usedCoupons/actions';

export default function UsedCoupons() {
  const usedCouponsTraditional = useSelector(
    (state) => state.coupons.usedCouponsTraditional
  );
  const usedCouponsFlash = useSelector(
    (state) => state.coupons.usedCouponsFlash
  );
  const [tabTraditional, setTabTraditional] = useState(true);
  const [tabFlash, setTabLightning] = useState(false);
  const [filter, setFilter] = useState('today');
  const usedCouponLoading = useSelector(
    (state) => state.coupons.usedCouponsLoading
  );
  const dispatch = useDispatch();

  // Load used traditional coupons
  useEffect(() => {
    dispatch(getUsedCouponsTraditionalRequest());
  }, [dispatch]);

  function handleTabSelected(selectedTab) {
    if (selectedTab === 'tabTraditional') {
      dispatch(getUsedCouponsTraditionalRequest());
      setTabTraditional(true);
      setTabLightning(false);
    } else if (selectedTab === 'tabFlash') {
      dispatch(getUsedCouponsFlashRequest());
      setTabTraditional(false);
      setTabLightning(true);
    }
  }

  function handleFilterChange(selectedOption) {
    // TODO: Chamar backend para filtrar a lita de histórico de uso
    setFilter(selectedOption);
  }

  return (
    <>
      <ContentPage>
        <PageTitle>Cupons Utilizados</PageTitle>
        <ActionsMenuBox>
          {false && (
            <>
              <ExportOptionsModal
                primaryOption="Exportar utilizados"
                options={[
                  { id: 'today', title: 'De hoje' },
                  { id: 'thisWeek', title: 'Desta semana' },
                  { id: 'thisMonth', title: 'Deste mês' },
                ]}
              />
              <SearchBox>
                <SearchButton>
                  <SearchImage
                    src={require('../../assets/images/search/search.png')}
                  />
                </SearchButton>
                <SearchInput placeholder="Busque por um cupom" />
              </SearchBox>
            </>
          )}
        </ActionsMenuBox>
        <CouponsBox>
          <TabsBox>
            <TabBox>
              <Tab onClick={() => handleTabSelected('tabTraditional')}>
                Tradicional
              </Tab>
              <TabSelected isVisible={tabTraditional} width="124px" />
            </TabBox>
            <TabBox>
              <Tab onClick={() => handleTabSelected('tabFlash')}>
                Relâmpago
              </Tab>
              <TabSelected isVisible={tabFlash} width="126px" />
            </TabBox>
            {false && (
              <SelectModal
                margleft="890px"
                margtop="35px"
                onSelectOption={(selectedOption) =>
                  handleFilterChange(selectedOption)
                }
                selectedOption="Hoje"
                options={[
                  { id: 'today', title: 'Hoje' },
                  { id: 'thisWeek', title: 'Essa semana' },
                  { id: 'thisMonth', title: 'Esse mês' },
                  { id: 'sinceBegin', title: 'Desde o início' },
                ]}
              />
            )}
          </TabsBox>
          {usedCouponLoading && (
            <Col height="20vh">
              <Col alignCenter justifyCenter>
                <Spinner
                  style={{
                    width: '1.8rem',
                    height: '1.8rem',
                    color: preto,
                  }}
                />
              </Col>
            </Col>
          )}
          {tabTraditional && !usedCouponLoading && (
            <UsedCouponTable
              data={usedCouponsTraditional}
              selectedTab="normal"
            />
          )}
          {tabFlash && !usedCouponLoading && (
            <UsedCouponTable data={usedCouponsFlash} selectedTab="flash" />
          )}
        </CouponsBox>
      </ContentPage>
    </>
  );
}
