import { takeLatest, call, put, all, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import { apiGlobal, apiAnunciante } from '../../../services/api';

import {
  verifyTokenSuccess,
  verifyTokenFailure,
  processPaymentCreditCardSuccess,
  processPaymentCreditCardFailure,
  processPaymentBoletoSuccess,
  processPaymentBoletoFailure,
  processPaymentPixSuccess,
  processPaymentPixFailure,
} from './actions';
import {
  setIpAddress
} from '../user/actions'
import {
  logout
} from '../auth/actions';

import { sendSubscriptionPlanRenovation, sendSubscriptionPlanChangeRenovation } from '../../../services/vendors/mixpanel';

const signaturePaymentData = (state) =>
  state.signaturePayment.signaturePaymentData;
const userReducerData = (state) => state.user;

export function* processPaymentBoletoRequest({ payload }) {
  try {
    const signaturePayment = yield select(signaturePaymentData);
    const userInfo = signaturePayment.advertiserInfo;

    // Call global API to create the boleto payment transaction
    const response = yield call(
      apiGlobal.post,
      `/anunciante/${userInfo.id}/payment/boleto/process`,
      {
        token: signaturePayment.payment_token,
        subscription_id: payload.selectedPlan.subscription_id,
        boleto_expiration_date: payload.boletoExpirationDate,
      }
    );

    yield put(processPaymentBoletoSuccess(response.data));
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      toast.error('Ocorreu um erro ao gerar o boleto. Tente novamente!', {
        autoClose: 6000,
      });
      yield put(processPaymentBoletoFailure());
    }
  }
}

export function* processPaymentCreditCardRequest({ payload }) {
  try {
    const signaturePayment = yield select(signaturePaymentData);
    const userInfo = signaturePayment.advertiserInfo;
    const userReducer = yield select(userReducerData);

    // Call global API to create the credit card payment transaction
    const response = yield call(
      apiGlobal.post,
      `/anunciante/${userInfo.id}/payment/credit-card/process`,
      {
        token: signaturePayment.payment_token,
        card: {
          number: payload.cardNumber,
          holder_name: payload.cardName,
          validity: payload.cardValidity,
          cvv: payload.cardCVV,
        },
        cpf_cnpj: payload.cpfCnpj,
        subscription_id: payload.selectedPlan.subscription_id,
      }
    );

    // Only send mixpanel change subscription plan if is a renovation
    if (payload.isRenewPayment) {
      const recurringPaymentData = signaturePayment.advertiserInfo.advertiserRecurring;
      const eventData = {
        id: userInfo.id,
        email: userInfo.user.email,
        name: userInfo.name,
        currentPlanId: recurringPaymentData.subscription.id,
        currentPlanName: recurringPaymentData.subscription.name,
        currentPlanAmount: recurringPaymentData.amount,
        currentPlanPeriod: recurringPaymentData.subscription.period,
        newPlanId: payload.selectedPlan.subscription_id,
        newPlanName: payload.selectedPlan.name,
        newPlanAmount: payload.selectedPlan.new_subscription_amount,
        newPlanPeriod: payload.selectedPlan.period,
        hasFreeTrialValid: userReducer.hasFreeTrialValid
      }

      //Mixpanel Event
      sendSubscriptionPlanRenovation(eventData)

      // User changed the signature plan
      if (recurringPaymentData.subscription.id !== payload.selectedPlan.subscription_id) {
        //Mixpanel Event
        sendSubscriptionPlanChangeRenovation(eventData)
      }
    }

    yield put(processPaymentCreditCardSuccess(response.data));
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      if (
        err?.response?.data?.error?.message ===
        'Request failed with status code 400'
      ) {
        toast.error('Ocorreu um erro ao processar a transação. Tente novamente', {
          autoClose: false,
        });
      } else {
        toast.error(
          err?.response?.data?.error?.message ||
          'Ocorreu um erro ao processar a transação. Tente novamente',
          {
            autoClose: false,
          }
        );
      }
      yield put(processPaymentCreditCardFailure());
    }
  }
}

export function* processPaymentPixRequest({ payload }) {
  try {
    const signaturePayment = yield select(signaturePaymentData);
    const userInfo = signaturePayment.advertiserInfo;

    // Call global API to create the pix payment transaction
    const response = yield call(
      apiGlobal.post,
      `/anunciante/${userInfo.id}/payment/pix/process`,
      {
        token: signaturePayment.payment_token,
        subscription_id: payload.selectedPlan.subscription_id,
      }
    );
    yield put(processPaymentPixSuccess(response.data));
  } catch (err) {    
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      toast.error('Ocorreu um erro ao gerar o pix. Tente novamente!', {
        autoClose: 6000,
      });
      yield put(processPaymentPixFailure());
    }
  }
}

export function* verifyTokenRequest({ payload }) {
  try {
    const { token, changePaymentMethod } = payload;

    const response = yield call(
      apiAnunciante.post,
      '/signature-payment/verify-token',
      { token, change_payment_method: changePaymentMethod }
    );
    
    yield put(verifyTokenSuccess(response.data, token));
  } catch (err) {
    yield put(verifyTokenFailure());
  }
}

export default all([
  takeLatest('@signaturePayment/VERIFY_TOKEN_REQUEST', verifyTokenRequest),
  takeLatest(
    '@signaturePayment/PROCESS_PAYMENT_BOLETO_REQUEST',
    processPaymentBoletoRequest
  ),
  takeLatest(
    '@signaturePayment/PROCESS_PAYMENT_CREDIT_CARD_REQUEST',
    processPaymentCreditCardRequest
  ),
  takeLatest(
    '@signaturePayment/PROCESS_PAYMENT_PIX_REQUEST',
    processPaymentPixRequest
  ),
]);
