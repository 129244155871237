import React from "react";
import { Spinner } from 'reactstrap';

import { GeneralText, GreenButton } from "../../../../../../styles/common";
import { Col, Row } from "../../../../../../styles/grid";
import { 
  SectionBox, 
} from '../../../../styles'
import {
  PaymentMethodInfoBox,
} from '../styles';
import { preto } from "../../../../../../styles/colors";
import { LoadingBox } from '../../../../../../pages/_layouts/styles/common';

import radialChecked from "../../../../../../assets/images/payment-methods/radial_btn_checked.png";
import radialBtn from "../../../../../../assets/images/payment-methods/radial_btn.png";
import cardPix from "../../../../../../assets/images/payment-methods/card_pix.png";
import cardCreditCard from "../../../../../../assets/images/payment-methods/card_credit_card.png";
import cardBarCode from "../../../../../../assets/images/payment-methods/card_bar_code.png";
import cardPixGreen from "../../../../../../assets/images/payment-methods/card_pix_green.png";
import cardBarCodeGreen from "../../../../../../assets/images/payment-methods/bar_code_green.png";
import cardCreditCardGreen from "../../../../../../assets/images/payment-methods/card_credit_card_green.png";
import paymentCardSafeSite from "../../../../../../assets/images/payment-methods/safe_site.png";
import paymentCardSafeSiteGoogle from "../../../../../../assets/images/payment-methods/safe_site_google.png";

import px2vw from "../../../../../../utils/responsiveness/px2vw";

import FieldOption from "../../../../../FieldOption";
import { formatCurrencyBRL } from "../../../../../../utils/utils";

import { 
  CreditCardFieldLabel, 
  CreditCardFieldPlaceholder, 
  CreditCardInfoText, 
  CVVFieldLabel, 
  CVVFieldPlaceholder, 
  ExpiryDateFieldLabel,
  ExpiryDateFieldPlaceholder, 
  OwnerCardDocFieldLabel, 
  OwnerCardDocFieldPlaceholder, 
  OwnerCardNameFieldLabel, 
  OwnerCardNameFieldPlaceholder, 
  TotalOrderAmountText,
  GeneratePixText,
  GenerateBoletoText,
  PayCreditCardText,
  RecommendedPaymentChoiceText,
  PixInfoText,
  PixInfoText1,
  PixInfoText2,
  PixInfoText3,
  BoletoInfoText,
  BoletoInfoExpiryText,
  BoletoInfoExpiryOpt1Text,
  BoletoInfoExpiryOpt2Text,
  BoletoInfoExpiryOpt3Text,
  BoletoInfoText1,
  BoletoInfoText2,
  SectionTitleText,
} from '../constants';
export default function PaymentDetailMobile({ handler }) {
  const PixOption = () => {
    return (
      <Col>
        <Row width='100%' margbottom={px2vw(80)}>
          <PaymentMethodInfoBox 
            width="100%" 
            margleft="0" 
            fontSize={px2vw(40)}
            pad={px2vw(20)}
            padleft={px2vw(35)}
            padright={px2vw(35)}
          >
            {PixInfoText}
          </PaymentMethodInfoBox>
        </Row>
        <GeneralText
          padtop={px2vw(10)}
          margleft={px2vw(20)}
          padbottom={px2vw(40)}
          fontsize={px2vw(45)}
          fontweight="normal"
          textalign='flex-start'
        >
          {PixInfoText1}
        </GeneralText>
        <GeneralText
          padtop={px2vw(10)}
          margleft={px2vw(20)}
          padbottom={px2vw(40)}
          fontsize={px2vw(45)}
          fontweight="normal"
          textalign='flex-start'
        >
          {PixInfoText2}
        </GeneralText>
        <GeneralText
          padtop={px2vw(10)}
          margleft={px2vw(20)}
          padbottom={px2vw(40)}
          fontsize={px2vw(45)}
          fontweight="normal"
          textalign='flex-start'
        >
          {PixInfoText3}
        </GeneralText>
        <GeneralText
          padtop={px2vw(80)}
          padbottom={px2vw(80)}
          fontsize={px2vw(50)}
          fontweight="normal"
          textalign='flex-start'
        >
          {TotalOrderAmountText} <b> {formatCurrencyBRL(handler.selectedPlan?.new_subscription_amount * handler.selectedPlan?.period, {})} </b>
        </GeneralText>
      </Col>
    )
  }

  const BoletoOption = () => {
    return (
      <Col>
        <Row width='100%' margbottom={px2vw(80)}>
          <PaymentMethodInfoBox 
            width="100%" 
            margleft="0" 
            fontSize={px2vw(40)}
            pad={px2vw(20)}
            padleft={px2vw(35)}
            padright={px2vw(35)}
          >
            {BoletoInfoText}  
          </PaymentMethodInfoBox>
        </Row>
        <GeneralText
          padtop={px2vw(10)}
          margleft={px2vw(20)}
          padbottom={px2vw(40)}
          fontsize={px2vw(45)}
          fontweight="normal"
          textalign='flex-start'
        >
          {BoletoInfoExpiryText}
        </GeneralText>
        <Row spaceBetween>
          <Row 
            width="auto"
            onClick={() => handler.setBoletoExpirationDate('0days')}
          >
            <img
              alt="boletoExpiration0Days"
              src={handler.boletoExpirationDate === '0days' ? radialChecked : radialBtn}
              height={px2vw(300)}
            />
            <GeneralText
              padtop={px2vw(5)}
              margleft={px2vw(20)}
              margright={px2vw(20)}
              padbottom={px2vw(40)}
              fontsize={px2vw(45)}
              fontweight="normal"
              textalign='flex-start'
            >
              {BoletoInfoExpiryOpt1Text}
            </GeneralText>
          </Row>
          
          <Row 
            width="auto"
            onClick={() => handler.setBoletoExpirationDate('1days')}
          >
            <img
              alt="boletoExpiration1Day"
              src={handler.boletoExpirationDate === '1days' ? radialChecked : radialBtn}
              height={px2vw(300)}
            />
            <GeneralText
              padtop={px2vw(5)}
              margleft={px2vw(20)}
              margright={px2vw(20)}
              padbottom={px2vw(40)}
              fontsize={px2vw(45)}
              fontweight="normal"
              textalign='flex-start'
            >
              {BoletoInfoExpiryOpt2Text}
            </GeneralText>
          </Row>
          <Row 
            width="auto"
            onClick={() => handler.setBoletoExpirationDate('2days')}
          >
            <img
              alt="boletoExpiration2Days"
              src={handler.boletoExpirationDate === '2days' ? radialChecked : radialBtn}
              height={px2vw(300)}
            />
            <GeneralText
              padtop={px2vw(5)}
              margleft={px2vw(20)}
              margright={px2vw(20)}
              padbottom={px2vw(40)}
              fontsize={px2vw(45)}
              fontweight="normal"
              textalign='flex-start'
            >
              {BoletoInfoExpiryOpt3Text}
            </GeneralText>
          </Row>
        </Row>
        <GeneralText
          padtop={px2vw(10)}
          margleft={px2vw(20)}
          padbottom={px2vw(40)}
          fontsize={px2vw(45)}
          fontweight="normal"
          textalign='flex-start'
        >
          {BoletoInfoText1}
        </GeneralText>
        <GeneralText
          padtop={px2vw(10)}
          margleft={px2vw(20)}
          padbottom={px2vw(40)}
          fontsize={px2vw(45)}
          fontweight="normal"
          textalign='flex-start'
        >
          {BoletoInfoText2}
        </GeneralText>
        <GeneralText
          padtop={px2vw(80)}
          padbottom={px2vw(80)}
          fontsize={px2vw(50)}
          fontweight="normal"
          textalign='flex-start'
        >
          {TotalOrderAmountText} <b> {formatCurrencyBRL(handler.selectedPlan?.new_subscription_amount * handler.selectedPlan?.period, {})} </b>
        </GeneralText>
      </Col>
    )
  }

  return (
    <Col 
      padtop={px2vw(60)}
    >
      <Row margbottom={px2vw(40)} justifyStart>
        <GeneralText
          fontsize={px2vw(80)}
          fontweight="bold"
        >
          {SectionTitleText}
        </GeneralText>  
      </Row>
      <SectionBox>
        <Col 
          padtop={px2vw(40)}
          padbottom={px2vw(40)}
          padleft={px2vw(50)}
          padright={px2vw(50)}
          alignStart
        >
          <Row margbottom={px2vw(80)}>
            {handler.availablePaymentMethods.includes('credit_card') && (
              <Col padright={px2vw(40)}>
                <img 
                  alt="creditCard"
                  src={handler.paymentMethod === 'credit_card' ? cardCreditCardGreen : cardCreditCard} 
                  height={px2vw(900)} 
                  onClick={() => handler.handleChoosePaymentMethod('credit_card')}
                />
                <GreenButton
                  width='auto' 
                  height={px2vw(100)} 
                  margtop={px2vw(10)}
                  fontsize={px2vw(35)} 
                  padding={px2vw(22)}
                >
                  {RecommendedPaymentChoiceText}
                </GreenButton>
              </Col>
            )}
            {handler.availablePaymentMethods.includes('pix') && (
              <Col padright={px2vw(40)}>
                <img 
                  alt="pix"
                  src={handler.paymentMethod === 'pix' ? cardPixGreen : cardPix} 
                  height={px2vw(900)} 
                  onClick={() => handler.handleChoosePaymentMethod('pix')}
                />
              </Col>
            )}
            {handler.availablePaymentMethods.includes('boleto') && (
              <Col>
                <img
                  alt="boleto"
                  src={handler.paymentMethod === 'boleto' ? cardBarCodeGreen : cardBarCode} 
                  height={px2vw(900)} 
                  onClick={() => handler.handleChoosePaymentMethod('boleto')}
                />
              </Col>
            )}
          </Row>
          {handler.paymentMethod === 'credit_card' && (
            <Col padbottom={px2vw(50)}>
              <PaymentMethodInfoBox 
                width="100%" 
                margleft="0" 
                fontSize={px2vw(40)}
                pad={px2vw(20)}
                padleft={px2vw(35)}
                padright={px2vw(35)}
              >
                {CreditCardInfoText}
              </PaymentMethodInfoBox>
              <FieldOption 
                fieldController={handler.setPaymentCardNumber} 
                fieldName={CreditCardFieldLabel} 
                fieldValue={handler.paymentCardNumber}
                placeHolder={CreditCardFieldPlaceholder}
                width={px2vw(1090)}
                maxLength="16"
              />
              <Row>
                <Col>
                  <FieldOption 
                    fieldController={handler.setPaymentCardValidity} 
                    fieldName={ExpiryDateFieldLabel}
                    fieldValue={handler.paymentCardValidity} 
                    maxLength="5"
                    placeHolder={ExpiryDateFieldPlaceholder}
                    width={px2vw(515)}
                  />
                </Col>
                <Col margleft={px2vw(40)}>
                  <FieldOption 
                    fieldController={handler.setPaymentCardCVV} 
                    fieldName={CVVFieldLabel}
                    fieldValue={handler.paymentCardCVV} 
                    maxLength="4"
                    placeHolder={CVVFieldPlaceholder}
                    width={px2vw(515)}
                  />
                </Col>
              </Row>
              <FieldOption 
                fieldController={handler.setPaymentCardName} 
                fieldName={OwnerCardNameFieldLabel}
                fieldValue={handler.paymentCardName}
                placeHolder={OwnerCardNameFieldPlaceholder}
                width={px2vw(1090)}
                maxLength="100"
              />
              <FieldOption 
                fieldController={handler.setPaymentCnpjCpf} 
                fieldName={OwnerCardDocFieldLabel}
                fieldValue={handler.paymentCpfCnpj}
                placeHolder={OwnerCardDocFieldPlaceholder}
                maxLength="18"
                width={px2vw(1090)}
              />
              <GeneralText
                padtop={px2vw(80)}
                fontsize={px2vw(50)}
                fontweight="normal"
                textalign='flex-start'
              >
                {TotalOrderAmountText} <b> {formatCurrencyBRL(handler.selectedPlan?.new_subscription_amount * handler.selectedPlan?.period, {})} </b>
              </GeneralText>
              {handler.selectedPlan?.period && handler.selectedPlan?.period > 1 && (
                <GeneralText
                  padbottom={px2vw(80)}
                  fontsize={px2vw(50)}
                  fontweight="normal"
                  textalign='flex-start'
                >
                  em <b>{` ${handler.selectedPlan?.period}x `}</b> de <b>{` ${formatCurrencyBRL(handler.selectedPlan?.new_subscription_amount, {})}`}</b>
                </GeneralText>
              )}
            </Col>
          )}
          {handler.paymentMethod === 'pix' && <PixOption />}
          {handler.paymentMethod === 'boleto' && <BoletoOption />}

          <Row alignCenter margtop={px2vw(40)}>
            <GreenButton 
              block
              margtop="0"
              height={px2vw(150)}
              onClick={handler.handlePaymentSubmit}
            >
              {handler.paymentLoading && (
                <LoadingBox>
                  <Spinner
                    style={{
                      width: '1.8rem',
                      height: '1.8rem',
                      color: preto,
                    }}
                  />
                </LoadingBox>
              )}
              {!handler.paymentLoading && handler.paymentMethod === 'credit_card' && PayCreditCardText}
              {!handler.paymentLoading && handler.paymentMethod === 'pix' && GeneratePixText}
              {!handler.paymentLoading && handler.paymentMethod === 'boleto' && GenerateBoletoText}
            </GreenButton>
          </Row>
        </Col>
      </SectionBox>
      <Row width={px2vw(1100)} padtop={px2vw(60)} justifyEnd>
        <Col alignEnd padleft={px2vw(200)}>
          <img alt="safeSite" src={paymentCardSafeSite} />
        </Col>
        <Col alignEnd padright={px2vw(160)}>
          <img alt="safeSiteGoogle" src={paymentCardSafeSiteGoogle} />
        </Col>
      </Row>
    </Col>
  );
}
