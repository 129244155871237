import React from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import Route from './Route';
import Login from '../pages/Login';
import LoginAdmin from '../pages/LoginAdmin';
import FirstSteps from '../pages/FirstSteps';
import TermsOfUse from '../pages/TermsOfUse';
import ResetAdminPassword from '../pages/ResetAdminPassword';
import SignaturePayment from '../pages/SignaturePayment';
import Dashboard from '../pages/Dashboard';
import ProfileAndSignature from '../pages/ProfileSignature';
import MyStore from '../pages/MyStore';
import Clients from '../pages/Clients';
import KuppiBoost from '../pages/KuppiBoost';
import ManageCoupons from '../pages/ManageCoupons';
import UsedCoupons from '../pages/UsedCoupons';
import LoyaltyCard from '../pages/LoyaltyCard';
import Catalog from '../pages/Catalog';
import Orders from '../pages/Orders';
import Reports from '../pages/Reports';
import Onboarding from '../pages/Onboarding';
import AskDownloadApp from '../pages/AskDownloadApp';
import Whatsappbot from '../pages/Whatsappbot';
import Whatsappchat from '../pages/Whatsappchat';
import DeliveryGuru from '../pages/DeliveryGuru';
import Ratings from '../pages/Ratings';
import SalesRecover from '../pages/SalesRecover';
import Financial from '../pages/Financial';

import {
  login,
  loginAdmin,
  firstSteps,
  onboarding,
  termsOfUse,
  resetAdminPassword,
  signaturePayment,
  blockedSignaturePayment,
  dashboard,
  profileAndSignature,
  myStore,
  clients,
  kuppiBoost,
  reports,
  manageCoupons,
  usedCoupons,
  loyaltyCard,
  catalog,
  orders,
  askDownloadApp,
  whatsappbot,
  whatsappchat,
  deliveryGuru,
  ratings,
  salesRecover,
  massMessage,
  financial,
} from './routeMap';

import { logout } from '../store/modules/auth/actions';
import MassMessage from '../pages/MassMessage';


export default function Routes() {
  const dispatch = useDispatch();

  return (
    <BrowserRouter>
      <Switch>
        <Route path={firstSteps} component={FirstSteps} noLayout isPrivate />
        <Route path={onboarding} component={Onboarding} noLayout isPrivate />
        <Route path={termsOfUse} component={TermsOfUse} noLayout />
        <Route
          path={resetAdminPassword}
          component={ResetAdminPassword}
          noLayout
        />
        <Route path={signaturePayment} component={SignaturePayment} noLayout />
        <Route
          path={blockedSignaturePayment}
          component={SignaturePayment}
          noLayout
        />
        <Route
          path={askDownloadApp}
          component={AskDownloadApp}
          isPrivate
          noLayout
        />
        <Route
          option="whatsapp_chat"
          path={whatsappchat}
          component={Whatsappchat}
          isPrivate
          permissionUser="whatsapp_bot_chat"
        />
        <Route
          option="dashboard"
          path={dashboard}
          component={Dashboard}
          isPrivate
        />
        <Route
          option="whatsappbot"
          path={whatsappbot}
          component={Whatsappbot}
          isPrivate
        />
        <Route
          option="profile"
          path={profileAndSignature}
          component={ProfileAndSignature}
          isPrivate
        />
        <Route
          option="manageCoupons"
          path={manageCoupons}
          component={ManageCoupons}
          isPrivate
        />
        <Route
          option="usedCoupons"
          path={usedCoupons}
          component={UsedCoupons}
          isPrivate
        />
        <Route
          option="loyaltyCard"
          path={loyaltyCard}
          component={LoyaltyCard}
          isPrivate
        />
        <Route option="myStore" path={myStore} component={MyStore} isPrivate />
        <Route option="catalog" path={catalog} component={Catalog} isPrivate />
        <Route option="orders" path={orders} component={Orders} isPrivate />
        <Route option="clients" path={clients} component={Clients} isPrivate />
        <Route option="ratings" path={ratings} component={Ratings} isPrivate />
        <Route
          option="kuppiBoost"
          path={kuppiBoost}
          component={KuppiBoost}
          isPrivate
        />
        <Route option="salesRecover" path={salesRecover} component={SalesRecover} isPrivate />
        <Route option="reports" path={reports} component={Reports} isPrivate />
        <Route
          option="deliveryGuru"
          path={deliveryGuru}
          component={DeliveryGuru}
          isPrivate
        />
        <Route
          option="massMessage"
          path={massMessage}
          component={MassMessage}
          isPrivate
        />
        <Route option="financial" path={financial} component={Financial} isPrivate permissionUser="automatic_pix" />
        <Route path={loginAdmin} component={LoginAdmin} noLayout />
        <Route path={login} component={Login} noLayout />
        <Route path="/" component={() => dispatch(logout())} />
      </Switch>
    </BrowserRouter>
  );
}
