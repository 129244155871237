import React from 'react';
import { Spinner } from 'reactstrap';

import { LoadingBox } from '../../../../../../pages/_layouts/styles/common';
import { Row, Col } from '../../../../../../styles/grid';
import {
  SectionBox,
  SectionTitle,
  SectionButton,
  SectionButtonText,
} from '../../../../styles';
import { AmountLabel } from '../../PlanOptions/styles';
import {
  FormPaymentData,
  PaymentInput,
  PaymentChoiceImage,
  PaymentMethodText,
  PaymentMethodText2,
  PaymentMethodText3,
  PaymentMethodTextBold,
  Separator,
  PaymentSecureImage,
  RecommendedPaymentChoice,
  PaymentMethodInfoBox,
} from '../styles';
import { preto } from '../../../../../../styles/colors';

import radialChecked from "../../../../../../assets/images/payment-methods/radial_btn_checked.png";
import radialBtn from "../../../../../../assets/images/payment-methods/radial_btn.png";

import px2vw from '../../../../../../utils/responsiveness/px2vw';
import { formatCurrencyBRL } from '../../../../../../utils/utils';

import { 
  CreditCardFieldLabel, 
  CreditCardFieldPlaceholder, 
  CreditCardInfoText, 
  CVVFieldLabel, 
  CVVFieldPlaceholder, 
  ExpiryDateFieldLabel,
  ExpiryDateFieldPlaceholder, 
  OwnerCardDocFieldLabel, 
  OwnerCardDocFieldPlaceholder, 
  OwnerCardNameFieldLabel, 
  OwnerCardNameFieldPlaceholder, 
  TotalOrderAmountText,
  GeneratePixText,
  GenerateBoletoText,
  PayCreditCardText,
  RecommendedPaymentChoiceText,
  PixInfoText,
  PixInfoText1,
  PixInfoText2,
  PixInfoText3,
  BoletoInfoText,
  BoletoInfoExpiryText,
  BoletoInfoExpiryOpt1Text,
  BoletoInfoExpiryOpt2Text,
  BoletoInfoExpiryOpt3Text,
  BoletoInfoText1,
  BoletoInfoText2,
  SectionTitleText,
} from '../constants';

export default function PaymentDetailWeb({ handler }) {
  return (
    <>
      <Row margtop={px2vw(30)}>
        <Row width={px2vw(800)} justifyStart>
          <SectionTitle>
            {SectionTitleText}
          </SectionTitle>
        </Row>
        <Row justifyEnd margright={px2vw(30)} margtop={px2vw(-4)}>
          <a
            href="https://transparencyreport.google.com/safe-browsing/search?url=portal.kuppi.com.br"
            target="_blank"
            rel="noopener noreferrer"
          >
            <PaymentSecureImage
              alt="secureSite"
              src={require('../../../../../../assets/images/payment-methods/secure-site.png')}
            />
          </a>
        </Row>
      </Row>
      <SectionBox>
        <Col margleft={px2vw(25)} margtop={px2vw(20)}>
          <FormPaymentData onSubmit={handler.handlePaymentSubmit}>
            <Row justifyStart margtop={px2vw(10)} padleft={px2vw(70)}>
              {/* Credit card */}
              {handler.availablePaymentMethods.includes('credit_card') && (
                <>
                  <Row
                    alignCenter
                    width="auto"
                    onClick={() => handler.handleChoosePaymentMethod('credit_card')}
                    disabled={handler.paymentLoading}
                    cursor="pointer"
                  >
                    <Row margtop={px2vw(-24)} relative width="auto">
                      <img
                        alt="creditCard"
                        src={handler.paymentMethod === 'credit_card' ? radialChecked : radialBtn}
                        height={px2vw(300)}
                      />
                    </Row>
                    <Col alignCenter margleft={px2vw(20)} width="auto">
                      <PaymentChoiceImage
                        alt="creditCardImg"
                        src={require('../../../../../../assets/images/payment-methods/credit-card@2x.png')}
                      />
                      <PaymentMethodTextBold>
                        Cartão de Crédito
                      </PaymentMethodTextBold>
                      <RecommendedPaymentChoice>
                        {RecommendedPaymentChoiceText}
                      </RecommendedPaymentChoice>
                    </Col>
                  </Row>
                  <Separator />
                </>
              )}
              {/* Pix */}
              {handler.availablePaymentMethods.includes('pix') && (
                <>
                  <Row
                    alignCenter
                    width="auto"
                    onClick={() => handler.handleChoosePaymentMethod('pix')}
                    disabled={handler.paymentLoading}
                    cursor="pointer"
                  >
                    <Row margtop={px2vw(5)} relative width="auto">
                      <img
                        alt="pix"
                        src={handler.paymentMethod === 'pix' ? radialChecked : radialBtn}
                        height={px2vw(300)}
                      />
                    </Row>
                    <Col alignCenter margleft={px2vw(20)} margtop={px2vw(2)} width="auto">
                      <PaymentChoiceImage
                        alt="pixImg"
                        width={px2vw(85)}
                        height={px2vw(30)}
                        src={require('../../../../../../assets/images/payment-methods/pix@2x.png')}
                      />
                      <PaymentMethodTextBold margtop={px2vw(2)}>Pix</PaymentMethodTextBold>
                    </Col>
                  </Row>
                  <Separator />
                </>
              )}
              {/* Boleto */}
              {handler.availablePaymentMethods.includes('boleto') && (
                <Row
                  alignCenter
                  width="auto"
                  onClick={() => handler.handleChoosePaymentMethod('boleto')}
                  disabled={handler.paymentLoading}
                  cursor="pointer"
                >
                  <Row margtop={px2vw(2)} relative width="auto">
                    <img
                      alt="boleto"
                      src={handler.paymentMethod === 'boleto' ? radialChecked : radialBtn}
                      height={px2vw(300)}
                    />
                  </Row>
                  <Col alignCenter margleft={px2vw(20)} width="auto">
                    <PaymentChoiceImage
                      alt="boletoImg"
                      src={require('../../../../../../assets/images/payment-methods/boleto@2x.png')}
                    />
                    <PaymentMethodTextBold margtop={px2vw(-2)}>Boleto</PaymentMethodTextBold>
                  </Col>
                </Row>
              )}
            </Row>
            <Col>
              {handler.paymentMethod === 'credit_card' && (
                <>
                  <PaymentMethodInfoBox>
                    {CreditCardInfoText}
                  </PaymentMethodInfoBox>
                  <Col
                    padleft={px2vw(40)}
                    margtop={px2vw(11)}
                    margbottom={px2vw(50)}
                    width={px2vw(580)}
                  >
                    <Row>
                      <Col width={px2vw(315)}>
                        <PaymentMethodTextBold>
                          {CreditCardFieldLabel}
                        </PaymentMethodTextBold>
                        <PaymentInput
                          name="paymentCardNumber"
                          maxLength="16"
                          width="100%"
                          placeholder={CreditCardFieldPlaceholder}
                          value={handler.paymentCardNumber}
                          onChange={handler.setPaymentCardNumber}
                        />
                      </Col>
                      <Col width={px2vw(95)} margleft={px2vw(25)}>
                        <PaymentMethodTextBold>
                          {ExpiryDateFieldLabel}
                        </PaymentMethodTextBold>
                        <PaymentInput
                          name="paymentCardValidity"
                          width="100%"
                          placeholder={ExpiryDateFieldPlaceholder}
                          maxLength="5"
                          value={handler.paymentCardValidity}
                          onChange={handler.setPaymentCardValidity}
                        />
                      </Col>
                      <Col width={px2vw(85)} margleft={px2vw(25)}>
                        <PaymentMethodTextBold>
                          {CVVFieldLabel}
                        </PaymentMethodTextBold>
                        <PaymentInput
                          name="paymentCardCVV"
                          width="100%"
                          placeholder={CVVFieldPlaceholder}
                          maxLength="4"
                          value={handler.paymentCardCVV}
                          onChange={handler.setPaymentCardCVV}
                        />
                      </Col>
                    </Row>
                    <Row margtop={px2vw(20)}>
                      <Col>
                        <PaymentMethodTextBold>
                          {OwnerCardNameFieldLabel}
                        </PaymentMethodTextBold>
                        <PaymentInput
                          name="paymentCardName"
                          width="100%"
                          maxLength="100"
                          placeholder={OwnerCardNameFieldPlaceholder}
                          value={handler.paymentCardName}
                          onChange={handler.setPaymentCardName}
                        />
                      </Col>
                      <Col margleft={px2vw(20)}>
                        <PaymentMethodTextBold>
                          {OwnerCardDocFieldLabel}
                        </PaymentMethodTextBold>
                        <PaymentInput
                          name="paymentCpfCnpj"
                          width="100%"
                          placeholder={OwnerCardDocFieldPlaceholder}
                          maxLength="18"
                          value={handler.paymentCpfCnpj}
                          onChange={handler.setPaymentCnpjCpf}
                        />
                      </Col>
                    </Row>
                  </Col>
                </>
              )}
              {handler.paymentMethod === 'pix' && (
                <>
                  <PaymentMethodInfoBox>
                    {PixInfoText}
                  </PaymentMethodInfoBox>
                  <Col
                    padleft={px2vw(40)}
                    margbottom={px2vw(54)}
                    margtop={px2vw(11)}
                    width={px2vw(580)}
                  >
                    <Row justifyStart>
                      <PaymentMethodText>
                        {PixInfoText1}
                      </PaymentMethodText>
                    </Row>
                    <Row justifyStart margtop={px2vw(12)}>
                      <PaymentMethodText>
                        {PixInfoText2}
                      </PaymentMethodText>
                    </Row>
                    <Row justifyStart margtop={px2vw(12)}>
                      <PaymentMethodText>
                        {PixInfoText3}
                      </PaymentMethodText>
                    </Row>
                  </Col>
                </>
              )}
              {handler.paymentMethod === 'boleto' && (
                <>
                  <PaymentMethodInfoBox>
                    {BoletoInfoText}
                  </PaymentMethodInfoBox>
                  <Col
                    padleft={px2vw(40)}
                    margbottom={px2vw(44)}
                    width={px2vw(580)}
                  >
                    <Col justifyStart>
                      <PaymentMethodText>
                        {BoletoInfoExpiryText}
                      </PaymentMethodText>
                      <Row justifyStart margleft={px2vw(20)} margtop={px2vw(5)}>
                        <Row 
                          alignCenter 
                          width="auto"
                          onClick={() => handler.setBoletoExpirationDate('0days')}
                          cursor="pointer"
                          margright={px2vw(10)}
                        >
                          <Row relative width="auto">
                            <img
                              alt="boleto0Days"
                              src={handler.boletoExpirationDate === '0days' ? radialChecked : radialBtn}
                              height={px2vw(300)}
                            />
                          </Row>
                          <Col width="auto">
                            <PaymentMethodText2>
                              {BoletoInfoExpiryOpt1Text}
                            </PaymentMethodText2>
                          </Col>
                        </Row>
                        <Row 
                          alignCenter 
                          width="auto"
                          onClick={() => handler.setBoletoExpirationDate('1day')}
                          cursor="pointer"
                          margright={px2vw(10)}
                        >
                          <Row relative width="auto">
                            <img
                              alt="boleto1Day"
                              src={handler.boletoExpirationDate === '1day' ? radialChecked : radialBtn}
                              height={px2vw(300)}
                            />
                          </Row>
                          <Col width="auto">
                            <PaymentMethodText2>
                              {BoletoInfoExpiryOpt2Text}
                            </PaymentMethodText2>
                          </Col>
                        </Row>
                        <Row 
                          alignCenter 
                          width="auto"
                          onClick={() => handler.setBoletoExpirationDate('2days')}
                          cursor="pointer"
                        >
                          <Row relative width="auto">
                            <img
                              alt="boleto2Days"
                              src={handler.boletoExpirationDate === '2days' ? radialChecked : radialBtn}
                              height={px2vw(300)}
                            />
                          </Row>
                          <Col width="auto">
                            <PaymentMethodText2>
                              {BoletoInfoExpiryOpt3Text}
                            </PaymentMethodText2>
                          </Col>
                        </Row>
                      </Row>
                    </Col>
                    <Row justifyStart margtop={px2vw(10)}>
                      <PaymentMethodText>
                        {BoletoInfoText1}
                      </PaymentMethodText>
                    </Row>
                    <Row justifyStart margtop={px2vw(10)}>
                      <PaymentMethodText>
                        {BoletoInfoText2}
                      </PaymentMethodText>
                    </Row>
                  </Col>
                </>
              )}
            </Col>
            <Row spaceBetween>
              <Row justifyStart alignCenter width="auto" margleft={px2vw(30)}>
                <PaymentMethodText3>
                  <Col>
                    <Row>
                      <span>{TotalOrderAmountText} </span>
                      <AmountLabel>
                        <span className="bold">
                          {` ${formatCurrencyBRL((handler.selectedPlan?.new_subscription_amount * handler.selectedPlan?.period), {})} `}
                        </span>
                      </AmountLabel>
                    </Row>
                    {handler.paymentMethod === 'credit_card'
                      && handler.selectedPlan?.period
                      && handler.selectedPlan?.period > 1 && (
                        <Row
                          alignCenter>
                          <AmountLabel>em
                            <span className="bold"> {` ${handler.selectedPlan?.period}x `}
                            </span>
                          </AmountLabel>
                          <AmountLabel>de
                            <span className="bold">
                              {` ${formatCurrencyBRL(handler.selectedPlan?.new_subscription_amount.toFixed(2), {})}`}
                            </span>
                          </AmountLabel>
                        </Row>
                      )}
                  </Col>
                  <br />
                </PaymentMethodText3>
              </Row>
              <Row width="auto">
                <SectionButton type="submit" disabled={handler.paymentLoading}>
                  {handler.paymentLoading ? (
                    <LoadingBox>
                      <Spinner
                        style={{
                          width: '1.8rem',
                          height: '1.8rem',
                          color: preto,
                        }}
                      />
                    </LoadingBox>
                  ) : (
                    <SectionButtonText>
                      {handler.paymentMethod === 'pix'
                        ? GeneratePixText
                        : handler.paymentMethod === 'boleto'
                          ? GenerateBoletoText
                          : PayCreditCardText}
                    </SectionButtonText>
                  )}
                </SectionButton>
              </Row>
            </Row>
          </FormPaymentData>
        </Col>
      </SectionBox>
    </>
  );
}
