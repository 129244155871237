import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { format, parse } from 'date-fns';
import moment from 'moment';

import { verifyTokenRequest } from '../../store/modules/signaturePayment/actions';
import RealTimeDimensions from '../../utils/responsiveness/RealTimeDimensions';

import SignaturePaymentWeb from './Web';
import SignaturePaymentMobile from './Mobile';

import { 
  processPaymentBoletoRequest, 
  processPaymentCreditCardRequest, 
  processPaymentPixRequest 
} from '../../store/modules/signaturePayment/actions';
import { onlyNumbers } from '../../utils/utils';
import { toast } from 'react-toastify';
import CpfCnpjValidator from '../../utils/validations/CpfCnpjValidator';
import { isCardDateValid } from '../../utils/validations/validations';
import { cardValidityMask, cpfCnpjMask } from '../../utils/masks';
import { formatDateFromString } from '../../utils/formatDate';

import { termsOfUse, signaturePayment as signaturePaymentRoute } from '../../routes/routeMap';

import history from '../../services/history';
import { setChangePaymentMethod } from '../../store/modules/signaturePayment/actions';

export default function SignaturePayment() {
  const [width] = RealTimeDimensions();
  const isWeb = width >= 800 ? true : false;
  const urlParams = new URLSearchParams(useLocation().search);
  const advertiserSubscriptions = useSelector((state) => state.user?.user?.advertiserInfo?.advertiserSubscription);
  const signaturePayment = useSelector((state) => state.signaturePayment);
  const [token, setToken] = useState(urlParams.get('t'));
  const { 
    loading, 
    success,
    boletoGenerated,
    creditCardPaid,
    pixGenerated,
    changePaymentMethod,
    signaturePaymentData,
    isRenewPayment,
    newPlanOptions,
    availablePaymentMethods,
    paymentLoading,
    showKuppiBoostDiscounts,
   } = signaturePayment;
  const recurringPaymentData = useSelector(
    (state) => state.signaturePayment?.signaturePaymentData?.advertiserInfo?.advertiserRecurring
  );
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [paymentCardNumber, setPaymentCardNumber] = useState('');
  const [paymentCpfCnpj, setPaymentCnpjCpf] = useState('');
  const [paymentCardName, setPaymentCardName] = useState('');
  const [paymentCardValidity, setPaymentCardValidity] = useState('');
  const [paymentCardCVV, setPaymentCardCVV] = useState('');
  const [boletoExpired, setBoletoExpired] = useState(false);
  const [boletoExpirationDate, setBoletoExpirationDate] = useState('0days');
  const [vigencyInfo, setVigencyInfo] = useState('');
  const [areBlocked, setAreBlocked] = useState(false);
  const [areCanceled, setAreCanceled] = useState(false);
  const [isFirstPayment, setIsFirstPayment] = useState(false);
  const dispatch = useDispatch();

useEffect(() => {
  const subscriptionWithPaymentToken = advertiserSubscriptions?.find(
    (e) => e.payment_token !== null
  )
  // Force set the pending payment token, when user are redirect to the pagamento-assinatura-bloqueada page
  if (!token) {
    setToken(subscriptionWithPaymentToken?.payment_token)
  }
}, [advertiserSubscriptions])
  
  useEffect(() => {
    if (token) {
      dispatch(verifyTokenRequest(token, changePaymentMethod));
    }
  }, [token]);

  useEffect(() => {
    if (success) {
      // Set user are blocked and or canceled or is first payment
      setAreBlocked(
        signaturePaymentData?.advertiserInfo?.is_overdue_payment
      );
      setAreCanceled(
        recurringPaymentData?.canceled_at !== null
      );
      setIsFirstPayment(!signaturePaymentData?.advertiserInfo?.is_overdue_payment && !isRenewPayment);
      // Set the selected plan with the user current plan
      const currPlanFromNewPlanOptions = newPlanOptions.find((newPlanOption) => newPlanOption.subscription_id === recurringPaymentData?.subscription?.id);
      setSelectedPlan(currPlanFromNewPlanOptions ? currPlanFromNewPlanOptions : {
        subscription_id: recurringPaymentData?.subscription?.id,
        discount_percentage: 0,
        new_subscription_amount: recurringPaymentData?.amount,
        name: recurringPaymentData?.subscription?.name,
        period: recurringPaymentData?.subscription?.period,
        slug: recurringPaymentData?.subscription?.slug,
        saved_amound: 0
      })
      // Define if boleto was expired or not
      if (signaturePaymentData?.boleto_expiration_at) {
        const boletoExpirationDateVerifyArray = signaturePaymentData?.boleto_expiration_at?.split(
          '/'
        );
        const boletoExpirationDateVerify = `${boletoExpirationDateVerifyArray[2]}-${boletoExpirationDateVerifyArray[1]}-${boletoExpirationDateVerifyArray[0]}T23:59:59`;
        setBoletoExpired(moment(new Date()) > moment(new Date(boletoExpirationDateVerify)));
      }
      // Set the payment method
      setPaymentMethod(availablePaymentMethods.includes('credit_card') ? 'credit_card' : availablePaymentMethods.includes('pix') ? 'pix' : 'boleto');
    }
  }, [success]);

  // Define the vigency data
  useEffect(() => {
    if (handler.selectedPlan && handler.recurringPaymentData) {
      if (isFirstPayment || areCanceled) {
        if (selectedPlan?.period > 1) {
          setVigencyInfo(
            `${selectedPlan?.period} meses após o pagamento`
          );
        } else {
          setVigencyInfo(
            `${selectedPlan?.period} mês após o pagamento`
          );
        }
      } else {
        const optionalStartDate = formatDateFromString(handler.recurringPaymentData?.created_at?.substring(0, 10))
        const startValidity = parse(handler.recurringPaymentData?.next_recurring_at ? handler.recurringPaymentData?.next_recurring_at : optionalStartDate, 'dd/MM/yyyy', new Date());
        startValidity.setMonth(startValidity.getMonth() - parseInt(handler.recurringPaymentData?.subscription?.period))
        const finishValidity = parse(format(startValidity, 'dd/MM/yyyy'), 'dd/MM/yyyy', new Date());
        finishValidity.setMonth(finishValidity.getMonth() + parseInt(handler.selectedPlan?.period)); 
        setVigencyInfo(`${format(startValidity, 'dd/MM/yyyy')} até ${format(finishValidity, 'dd/MM/yyyy')}`);
      }
    }
  }, [selectedPlan]);

  // Copy Boleto Linha Digitavel or Pix Code
  function handleCopyPaymentCode(type) {
    var copyText = document.getElementById(type);
    var textArea = document.createElement("textarea");
    textArea.value = copyText.textContent;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand("Copy");
    textArea.remove();

    if (type === 'boletoBarCodeCopy') {
      toast.info('A linha digitável do boleto foi copiada com sucesso!');
    } else if ( type === 'pixQrCodeCopy') {
      toast.info('O código do Pix foi copiado com sucesso!');
    } 
  }

  // Change the payment method button
  function handleChangePaymentMethod() {
    // Set the change payment method is necessary
    dispatch(setChangePaymentMethod());

    // Go back to the payment link URL
    history.push({
      pathname: signaturePaymentRoute,
      search: `?t=${signaturePaymentData?.payment_token}`,
    });
  }

  // Choose other payment method
  function handleChoosePaymentMethod(paymentMethod) {
    setPaymentMethod(paymentMethod);
    // Clear credit card fields
    setPaymentCardNumber('');
    setPaymentCardValidity('');
    setPaymentCardCVV('');
    setPaymentCardName('');
    setPaymentCnpjCpf('');
    // Clear boleto field
    setBoletoExpirationDate('0days');
  }

  // Payment data submit
  function handlePaymentSubmit() {
    if (paymentMethod === 'pix') {
      dispatch(processPaymentPixRequest(selectedPlan, isRenewPayment));
    } else if (paymentMethod === 'boleto') {
      dispatch(processPaymentBoletoRequest(selectedPlan, onlyNumbers(boletoExpirationDate), isRenewPayment));
    } else if (
      paymentCardNumber === '' ||
      paymentCardValidity === '' ||
      paymentCardCVV === '' ||
      paymentCardName === '' ||
      paymentCpfCnpj === ''
    ) {
      toast.error('Todos os campos são obrigatórios!', {
        autoClose: 6000,
      });
    } else if (paymentCardNumber.length < 14 || paymentCardNumber.length > 16) {
      toast.error('Cartão inválido!', {
        autoClose: 6000,
      });
    } else if (paymentCardCVV.length < 3 || paymentCardCVV.length > 4) {
      toast.error(
        'Código de segurança inválido. O código deve ter 3 ou 4 dígitos',
        {
          autoClose: 6000,
        }
      );
    } else if (!CpfCnpjValidator(paymentCpfCnpj)) {
      toast.error(
        'CPF/CNPJ inválido. CPF deve ter 11 dígitos e CNPJ 14 dígitos',
        {
          autoClose: 6000,
        }
      );
    } else if (
      paymentCardValidity.length < 5 ||
      paymentCardValidity.length > 5
    ) {
      toast.error(
        'Data de validade do cartão inválida. O formato esperado é: MM/AA',
        {
          autoClose: 6000,
        }
      );
    } else if (!isCardDateValid(paymentCardValidity)) {
      toast.error(
        'Cartão expirado, verifique se a data informada está correta e no formato: MM/AA.',
        {
          autoClose: 6000,
        }
      );
    } else {
      dispatch(
        processPaymentCreditCardRequest(
          selectedPlan,
          paymentCardNumber,
          onlyNumbers(paymentCardValidity),
          paymentCardCVV,
          paymentCardName,
          onlyNumbers(paymentCpfCnpj),
          isRenewPayment
        )
      );
    }
  };  

  const handler = {
    success: success,
    loading: loading,
    boletoGeneratedSuccess: boletoGenerated,
    creditCardPaidSuccess: creditCardPaid,
    pixGeneratedSuccess: pixGenerated,
    areBlocked: areBlocked,
    areCanceled: areCanceled,
    termsOfUseLink: termsOfUse,
    recurringPaymentData: recurringPaymentData,
    signaturePaymentData: signaturePaymentData,
    availablePaymentMethods: availablePaymentMethods,
    isRenewPayment: isRenewPayment,
    newPlanOptions: newPlanOptions,
    selectedPlan: selectedPlan,
    paymentLoading: paymentLoading,
    boletoExpired: boletoExpired,
    isFirstPayment: isFirstPayment,
    vigencyInfo: vigencyInfo,
    paymentCardCVV: paymentCardCVV,
    paymentCardValidity: paymentCardValidity,
    paymentCpfCnpj: paymentCpfCnpj,
    paymentCardNumber: paymentCardNumber,
    paymentMethod: paymentMethod,
    paymentCardName: paymentCardName,
    boletoExpirationDate: boletoExpirationDate,
    showKuppiBoostDiscounts: showKuppiBoostDiscounts,
    handlePaymentSubmit: (obj) => handlePaymentSubmit(obj),
    handleCopyPaymentCode: (type) => handleCopyPaymentCode(type),
    handleChangePaymentMethod: () => handleChangePaymentMethod(),
    handleChoosePaymentMethod: (val) => handleChoosePaymentMethod(val),
    setSelectedPlan: (obj) => setSelectedPlan(obj),
    setBoletoExpirationDate: (val) => setBoletoExpirationDate(val),
    setPaymentCardNumber: (e) => setPaymentCardNumber(onlyNumbers(e.target.value)),
    setPaymentCardValidity: (e) => setPaymentCardValidity(cardValidityMask(e.target.value)),
    setPaymentCardCVV: (e) => setPaymentCardCVV(onlyNumbers(e.target.value)),
    setPaymentCardName: (e) => setPaymentCardName(e.target.value),
    setPaymentCnpjCpf: (e) => setPaymentCnpjCpf(cpfCnpjMask(e.target.value))
  }

  if (isWeb) {
    return <SignaturePaymentWeb handler={handler} />;
  } else {
    return <SignaturePaymentMobile handler={handler} />;
  }
}
