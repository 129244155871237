import React from 'react';

import { PageTitle, ContentPage } from '../_layouts/styles/common';
import { SubtitleText, SubtitleTextBox, TipsBox, TipsImage, TipsSubtitleText, TipsTitleText } from './styles';

import px2vw from '../../utils/responsiveness/px2vw';
import { Col, Row } from '../../styles/grid';

export default function DeliveryGuru() {
  return (
    <ContentPage>
      <PageTitle>Guru do Delivery</PageTitle>
      <SubtitleTextBox>
        <SubtitleText>
          A inteligência artificial que te ajudará no dia a dia
        </SubtitleText>
      </SubtitleTextBox>
      <TipsBox>
        <Row spaceBetween>
          <Col padtop={px2vw(20)}>
            <TipsTitleText>Dicas Úteis</TipsTitleText>
            <TipsSubtitleText>• Contextualize suas perguntas, isso melhora a qualidade das respostas</TipsSubtitleText>
            <TipsSubtitleText>• Caso a resposta pareça genérica, peça mais informações</TipsSubtitleText>
            <TipsSubtitleText>• Experimente pedir por exemplos ou planos de ação como de como implementar as dicas</TipsSubtitleText>
          </Col>
          <TipsImage src={require('../../assets/images/delivery_guru/delivery_guru_tips.png')} />
        </Row>
      </TipsBox>
      <div dangerouslySetInnerHTML={{__html: `
        <zapier-interfaces-page-embed page-id='clg3w18r80008lc0f5jqm3kfb' no-background='false' style='max-width: ${px2vw(1028)}; height: ${px2vw(600)};'></zapier-interfaces-page-embed>
      `}}/>
    </ContentPage>
  );
}
