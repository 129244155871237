import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Progress } from 'reactstrap';
import { toast } from 'react-toastify';
import { Paper } from '@material-ui/core';
import { InputBase } from '@material-ui/core';
import { CircularProgress } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

import {
  Container,
  Span,
  ProgressBar,
  BackIcon,
  Select,
  Option,
  Input,
  ButtonPrimary,
  ModalFooter,
  inputMui,
  formMui,
  iconMui,
  IconBox,
  Box,
  Icon,
  predictionItem,
  AddressBox,
} from './styles';
import {
  cinzaKuppi1,
  preto,
  verdeKuppi5,
  cinzaKuppi10,
  verdeKuppi2,
  branco,
} from '../../../styles/colors';
import { Row, Col } from '../../../styles/grid';

import {
  getAddressDetails,
  getAddressList,
  getFieldFromAddressComponents,
  getLatLngByAddressFields,
} from '../../../services/Addresses';

import {
  getRouteDistanceBetweenAddresses,
  getDistanceBetweenAddresses,
} from '../../../utils/location';
import {
  AddressFields,
  buildSearchTermAutocomplete,
} from '../../../utils/Adresses';
import { formatAddress } from '../../../utils/masks';

import px2vw from '../../../utils/responsiveness/px2vw';
import { statesWithId } from '../../../utils/commonObjects';
import debounce from '../../../utils/debounce/debounce';
import { onlyNumbers } from '../../../utils/utils';
import { useConstant } from '../../../utils/hooks';

import {
  setCurrStep,
  setAddress,
  addAddress,
  deleteAddress,
  setDeliveryFee,
} from '../../../store/modules/manualOrder/actions';

export default function AddressesData() {
  const dispatch = useDispatch();
  const global = useSelector((state) => state);
  const isLoggedin = useSelector((state) => state.auth?.signed);
  const previousStep = useSelector((state) => state.manualOrder?.previousStep);
  const feeCalcType = useSelector(
    (state) => state.manualOrder?.advertiser?.delivery_dynamic_fee_calc_type
  );
  const deliveryRangeArea = useSelector(
    (state) => state.manualOrder?.advertiser?.delivery_range_area
  );
  const [currentAddress, setCurrentAddress] = useState(
    global.manualOrder?.address ||
      global.manualOrder?.addresses.find((address) => address.is_default) ||
      null
  );
  const [cityData, setCityData] = useState('');
  const [streetData, setStreetData] = useState('');
  const addresses = global?.manualOrder?.addresses;
  const [houseNumber, setHouseNumber] = useState('');
  const [zipCodeData, setZipCodeData] = useState('');
  const [complementData, setComplementData] = useState('');
  const [autocompletedAddress, setAutocompletedAddress] = useState(null);
  const [autocompleteInput, setAutocompleteInput] = useState('');
  const [selectedPrediction, setSelectedPrediction] = useState(null);
  const [selectedAddressLocation, setSelectedAddressLocation] = useState(null);
  const [selectedAddressComponents, setSelectedAddressComponents] = useState(
    []
  );
  const [loading, setLoading] = useState(false);
  const [isAddingNewAddress, setIsAddingNewAddress] = useState(false);
  const [isAddressBoxVisible, setIsAddressBoxVisible] = useState(false);
  const [addressDescription, setAddressDescription] = useState('');
  const [totalDistanceInMeters, setTotalDistanceInMeters] = useState(0);

  const advertiserAddress = global?.manualOrder?.advertiser?.user?.address[0];
  const deliveryTakeawayConfig =
    global?.manualOrder?.advertiser?.advertiserDeliveryTakeawayConfig[0];

  const neighborhoodAreaConfigs =
    global?.manualOrder?.advertiser?.advertiserNeighborhoodAreaConfig;

  const deliveryType = global.manualOrder?.bag?.deliveryType;

  const neighborhoods = neighborhoodAreaConfigs?.map((n) => ({
    label: `${n.district.name}, ${n.district.locality.name} - ${n.district.locality.uf}`,
    value: String(n.district.id),
  }));

  const deliveryFee = global.manualOrder?.advertiser?.delivery_fee;
  const deliveryDynamicFeeMaxDist =
    global.manualOrder?.advertiser?.delivery_dynamic_fee_max_dist;
  const deliveryDynamicFee =
    global.manualOrder?.advertiser?.delivery_dynamic_fee;
  const deliveryDynamicFeeExceededAmount =
    global.manualOrder?.advertiser?.delivery_dynamic_fee_exceeded_amount;
  const deliveryDynamicFeeFreeDist =
    global.manualOrder?.advertiser?.delivery_dynamic_fee_free_dist;

  const isUsingNeighborhoodArea =
    !deliveryTakeawayConfig?.is_using_range_area &&
    deliveryTakeawayConfig?.is_using_neighborhood_area;

  const DeliveryTypes = {
    DYNAMIC: 'dynamic',
    FREE: 'free',
    UNIQUE: 'unique',
  };

  const getFeeType = useMemo(() => {
    if (deliveryFee === 0 && deliveryDynamicFee !== 0) {
      return DeliveryTypes.DYNAMIC;
    }

    if (deliveryFee === 0 && deliveryDynamicFee === 0) {
      return DeliveryTypes.FREE;
    }

    if (deliveryFee !== 0 && deliveryDynamicFee === 0) {
      return DeliveryTypes.UNIQUE;
    }
  }, [deliveryDynamicFee, deliveryFee]);

  const ufs = statesWithId || [];
  const [selectedNeighborhood, setSelectedNeighborhood] = useState(null);
  const [selectedNeighborhoodAreaConfig, setSelectedNeighborhoodAreaConfig] =
    useState(null);

  useEffect(() => {
    if (global.manualOrder?.addresses?.length === 0) {
      dispatch(setAddress(null));
    }
  }, [global.manualOrder?.addresses]);

  useEffect(() => {
    const selectedDistrictId = Number(selectedNeighborhood);
    const foundNeighborhoodAreaConfig = neighborhoodAreaConfigs.find(
      (n) => n.dne_district_id === selectedDistrictId
    );
    setSelectedNeighborhoodAreaConfig(
      () => foundNeighborhoodAreaConfig ?? null
    );

    setCityData(foundNeighborhoodAreaConfig?.district?.locality?.name);
  }, [selectedNeighborhood, neighborhoodAreaConfigs]);

  const onChange = async (value) => {
    setLoading(true);
    if (value === '') {
      setLoading(false);
      setAutocompletedAddress(null);
    } else {
      const addressList = await getAddressList(value);
      setAutocompletedAddress(addressList);
    }
    setLoading(false);
  };

  const debouncedOnChange = useConstant(() => debounce(onChange));

  function handleBackTo() {
    if (previousStep) {
      dispatch(setCurrStep(previousStep));
    } else {
      dispatch(setCurrStep('2'));
    }
  }

  function handleSaveAdress() {
    if (streetData) {
      const addressDetails = {
        street: streetData,
        number: houseNumber.trim() === '' ? 'S/N' : houseNumber,
        complement: complementData,
        neighborhood: selectedNeighborhoodAreaConfig?.district?.name,
        city: selectedNeighborhoodAreaConfig?.district?.locality?.name,
        state: selectedNeighborhoodAreaConfig?.district?.locality?.uf,
        dne_district_id: selectedNeighborhoodAreaConfig?.dne_district_id,
        zipcode: null,
        latitude: null,
        longitude: null,
      };

      const neighborhoodAreaConfig = neighborhoodAreaConfigs.find(
        (n) =>
          n.district?.name?.toLowerCase() ===
            addressDetails?.neighborhood?.toLowerCase() &&
          n.district?.uf?.toLowerCase() ===
            addressDetails?.state?.toLowerCase() &&
          n.district?.id === addressDetails?.dne_district_id
      );

      let actualDeliveryFee = neighborhoodAreaConfig
        ? neighborhoodAreaConfig.delivery_fee
        : deliveryFee;

      dispatch(setDeliveryFee(actualDeliveryFee));
      dispatch(setAddress(addressDetails));
      if (previousStep) {
        dispatch(setCurrStep(previousStep));
      } else {
        dispatch(setCurrStep('3'));
      }
      return;
    }

    if (getDeliveryValue) {
      dispatch(setDeliveryFee(getDeliveryValue));
    }

    if (previousStep) {
      dispatch(setCurrStep(previousStep));
    } else {
      dispatch(setCurrStep('3'));
    }
  }

  const handleOnSelectPrediction = useCallback(async (item) => {
    try {
      setHouseNumber('');
      setComplementData('');

      const details = await getAddressDetails(item.place_id);
      const addressComponents = details.result.address_components;
      const addressLocation = details.result.geometry.location;

      const number = getFieldFromAddressComponents(
        AddressFields.NUMBER,
        addressComponents
      );

      const zipcode = getFieldFromAddressComponents(
        AddressFields.ZIPCODE,
        addressComponents
      );

      if (number) {
        setHouseNumber(onlyNumbers(number.split(" ")[0]) || '');
      }

      if (zipcode) {
        setZipCodeData(zipcode || '');
      }

      setAddressDescription(details.result.formatted_address);
      setSelectedPrediction(item);
      setSelectedAddressComponents(addressComponents);
      setSelectedAddressLocation(addressLocation);
    } catch (err) {
      toast.error('Ocorreu um erro ao selecionar este endereço.');
    }

    setIsAddressBoxVisible(true);
  }, []);

  const onSubmitSelectedPrediction = useCallback(
    async (data) => {
      try {
        const street = getFieldFromAddressComponents(
          AddressFields.STREET,
          selectedAddressComponents
        );

        const city = getFieldFromAddressComponents(
          AddressFields.CITY,
          selectedAddressComponents
        );
        const uf = getFieldFromAddressComponents(
          AddressFields.STATE,
          selectedAddressComponents
        );

        let number = getFieldFromAddressComponents(
          AddressFields.NUMBER,
          selectedAddressComponents
        );
        if (number) {
          number = number?.split(" ")[0] || '';
        }

        const zipcode = getFieldFromAddressComponents(
          AddressFields.ZIPCODE,
          selectedAddressComponents
        );
        const neighborhood = getFieldFromAddressComponents(
          AddressFields.NEIGHBORHOOD,
          selectedAddressComponents
        );

        const houseNumber = data?.number?.trim() !== '' ? data?.number : number?.trim() !== '' ? number : 'S/N';

        if (selectedPrediction?.types?.includes('point_of_interest') && 
          selectedAddressLocation.latitude && selectedAddressLocation.longitude) {
          const addressDetails = {
            street: selectedPrediction?.structured_formatting?.main_text,
            number: houseNumber,
            complement: data.complement,
            neighborhood: neighborhood.trim() !== '' ? neighborhood : 'N/A',
            city,
            state: uf,
            zipcode: onlyNumbers(zipcode),
            latitude: selectedAddressLocation.latitude,
            longitude: selectedAddressLocation.longitude,
          };

          handleChangeAddress({ ...addressDetails, is_default: true }, true);
          setAutocompletedAddress([]);
          setAutocompleteInput('');
          setIsAddressBoxVisible(false);
          return;
        }

        const foundLocation = await getLatLngByAddressFields({
          street,
          number: houseNumber,
          neighborhood: data.neighborhood || neighborhood,
          city,
          state: uf,
          zipcode: onlyNumbers(zipcode),
        });

        const addressDetails = {
          street: selectedPrediction.structured_formatting.main_text,
          number: houseNumber,
          neighborhood: neighborhood.trim() !== '' ? neighborhood : 'N/A',
          complement: data.complement,
          city,
          state: uf,
          zipcode: onlyNumbers(zipcode),
          latitude: foundLocation.latitude,
          longitude: foundLocation.longitude,
        };

        handleChangeAddress({ ...addressDetails, is_default: true }, true);
        setAutocompletedAddress([]);
        setAutocompleteInput('');
        setIsAddressBoxVisible(false);
      } catch (err) {
        const message = err.response?.data?.error?.message;
        if (message) {
          toast.error(message);
        } else {
          toast.error('Ocorreu um erro ao salvar o endereço. Tente novamente e caso o erro persista entre em contato com o suporte!');
        }
      }
    },
    [
      dispatch,
      currentAddress,
      selectedAddressComponents,
      selectedAddressLocation,
      selectedPrediction,
    ]
  );

  const calculateTotalDistanceInMeters = useCallback(async () => {
    if (!isLoggedin) {
      setTotalDistanceInMeters(0);
      return;
    }
    if (
      advertiserAddress &&
      currentAddress &&
      !deliveryTakeawayConfig.is_using_neighborhood_area && // Neighborhood area config
      deliveryTakeawayConfig.is_using_range_area // Range area config
    ) {
      setLoading(true);
      try {
        // Force not delivery to the address, when the dynamic delivery fee calculation
        // type (route or radius) was not filled at advertiser data
        let distance = 999999;
        // If address is associated with neighborhood (latitude and longitude is null)
        // Get the distance based on google routing
        if (advertiserAddress.dne_district_id) {
          setTotalDistanceInMeters(distance);
          return;
        }

        if (feeCalcType === 'route') {
          distance = await getRouteDistanceBetweenAddresses(
            {
              lat: advertiserAddress?.latitude,
              lng: advertiserAddress?.longitude,
            },
            {
              lat: currentAddress?.latitude,
              lng: currentAddress?.longitude,
            }
          );
        } else if (feeCalcType === 'radius') {
          // Get the distance based on the radius
          distance = getDistanceBetweenAddresses(
            advertiserAddress,
            currentAddress
          );
        } else {
          throw new Error('Erro ao calcular taxa de entrega.');
        }

        setTotalDistanceInMeters(distance);
      } catch (err) {
        toast.error('Ocorreu um erro ao calcular sua taxa de entrega.');
      } finally {
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  }, [
    advertiserAddress,
    deliveryTakeawayConfig,
    advertiserAddress,
    isLoggedin,
    feeCalcType,
    currentAddress,
  ]);

  useEffect(() => {
    if (
      deliveryType === 'delivery' &&
      !deliveryTakeawayConfig.is_using_neighborhood_area &&
      deliveryTakeawayConfig.is_using_range_area
    ) {
      calculateTotalDistanceInMeters();
    } else {
      setLoading(false);
    }
  }, [deliveryType, calculateTotalDistanceInMeters, deliveryTakeawayConfig]);

  const getDeliveryValue = useMemo(() => {
    let deliveryValue = 0;

    switch (getFeeType) {
      case DeliveryTypes.FREE:
      case DeliveryTypes.UNIQUE:
        return deliveryType === 'take_away' ? 0 : deliveryFee;
      case DeliveryTypes.DYNAMIC:
        if (
          !currentAddress ||
          !advertiserAddress ||
          !currentAddress.latitude ||
          !currentAddress.longitude ||
          !advertiserAddress.longitude ||
          !advertiserAddress.longitude
        ) {
          return 0;
        }

        const totalDistanceInKm = Math.ceil(totalDistanceInMeters / 1000);
        if (!totalDistanceInKm && !deliveryDynamicFeeFreeDist) {
          deliveryValue = deliveryDynamicFee;
        } else if (totalDistanceInKm <= deliveryDynamicFeeFreeDist) {
          deliveryValue = 0;
        } else if (totalDistanceInKm <= deliveryDynamicFeeMaxDist) {
          deliveryValue = deliveryDynamicFee;
        } else {
          const exceedingKm = totalDistanceInKm - deliveryDynamicFeeMaxDist;
          const exceedingDeliveryValue =
            exceedingKm * deliveryDynamicFeeExceededAmount;
          deliveryValue = deliveryDynamicFee + exceedingDeliveryValue;
        }

        return deliveryValue;
      default:
        break;
    }
  }, [
    currentAddress,
    deliveryTakeawayConfig,
    advertiserAddress,
    deliveryFee,
    getFeeType,
    deliveryDynamicFee,
    deliveryDynamicFeeMaxDist,
    deliveryDynamicFeeExceededAmount,
    deliveryDynamicFeeFreeDist,
    totalDistanceInMeters,
    deliveryType,
    neighborhoodAreaConfigs,
    addresses,
    selectedNeighborhood,
  ]);

  const handleChangeAddress = useCallback(
    (address, isNewAddress, isUsingNeighborhoodArea) => {
      let advertiserDeliversInSelectedAddress = false;

      // Validating if advertiser delivers in selected address
      if (isUsingNeighborhoodArea) {
        setSelectedNeighborhood(address.dne_district_id);
        const foundNeighborhoodAreaConfig = neighborhoodAreaConfigs.find(
          (n) =>
            n.district.name.toLowerCase() ===
              address?.neighborhood?.toLowerCase() &&
            n.district.uf.toLowerCase() === address?.state?.toLowerCase() &&
            n.district.id === address.dne_district_id
        );
        advertiserDeliversInSelectedAddress =
          foundNeighborhoodAreaConfig !== undefined;

        if(foundNeighborhoodAreaConfig){
          let actualDeliveryFee = foundNeighborhoodAreaConfig.delivery_fee
          dispatch(setDeliveryFee(actualDeliveryFee));
        }

      } else {
        const distanceToAdvertiser = getDistanceBetweenAddresses(address, advertiserAddress);
        advertiserDeliversInSelectedAddress = distanceToAdvertiser <= deliveryRangeArea * 1000;
      }

      if (advertiserDeliversInSelectedAddress) {
        if (!isNewAddress) {
          const addrss = addresses.find(
            (adrs) =>
              adrs.latitude === address.latitude &&
              adrs.longitude === address.longitude &&
              adrs.id === address?.id
          );

          dispatch(setAddress(addrss));
          setCurrentAddress(addrss);
        } else {
          dispatch(addAddress(address));
          dispatch(setAddress(address));
          setCurrentAddress(address);
        }
      } else {
        toast.error(
          'O endereço selecionado está fora da sua área de entrega, escolha outro',
          { autoClose: 6000 }
        );
      }
    },
    [calculateTotalDistanceInMeters]
  );

  function handleDeleteAddress(address) {
    dispatch(deleteAddress(address));
  }

  function handleSubmit(e) {
    e.preventDefault();
  }

  return (
    <>
      <ProgressBar>
        <Progress
          value={70}
          bar={true}
          style={{
            backgroundColor: verdeKuppi5,
            borderRaduis: '0px',
            height: '10px',
          }}
        />
      </ProgressBar>
      <Row
        justifyStart
        alignCenter
        className="py-1 px-2"
        cursor="pointer"
        background={cinzaKuppi1}
        onClick={() => handleBackTo()}
      >
        <BackIcon
          src={require('../../../assets/images/arrows/arrow_left.png')}
        />
        <Span padding={px2vw(3)} color={preto}>
          Endereço de Entrega
        </Span>
      </Row>
      <Container className="px-0 py-2 mt-2">
        <Col className="px-5">
          {!isUsingNeighborhoodArea ? (
            <>
              <Paper
                onSubmit={handleSubmit}
                component="form"
                sx={{
                  p: '2px 4px',
                  display: 'flex',
                  alignItems: 'center',
                  width: '100%',
                }}
                style={formMui}
              >
                <IconBox>
                  {!loading ? (
                    <SearchIcon style={iconMui} aria-label="search" />
                  ) : (
                    <CircularProgress style={iconMui} size="sm" />
                  )}
                </IconBox>

                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  placeholder="Digite seu endereço com número"
                  inputProps={{
                    'aria-label': 'search google maps',
                    value: autocompleteInput,
                    type: 'text',
                    list: 'addresses',
                    onChange: (e) => setAutocompleteInput(e.target.value),
                  }}
                  style={inputMui}
                  onChange={(e) =>
                    debouncedOnChange(
                      buildSearchTermAutocomplete(
                        e.target.value,
                        advertiserAddress
                      )
                    )
                  }
                />
              </Paper>

              <Col id="addresses" className="mt-1">
                {autocompletedAddress?.predictions?.length > 0 &&
                  autocompletedAddress?.predictions?.map((address, idx) => (
                    <>
                      <Row
                        key={idx}
                        height={px2vw(35)}
                        style={predictionItem}
                        className="mt-2"
                        onClick={() => handleOnSelectPrediction(address)}
                        cursor="pointer"
                      >
                        <Row justifyCenter alignCenter height="100%" width="7%">
                          <Icon
                            src={require('../../../assets/images/pin/pin.png')}
                            className="mx-0"
                          />
                        </Row>
                        <Col justifyStart width="93%">
                          <Row justifyStart>
                            <Span
                              className="mx-0"
                              fontWeight="700"
                              color={cinzaKuppi10}
                              fontSize={px2vw(11)}
                              cursor="pointer"
                            >
                              {address.structured_formatting.main_text}
                            </Span>
                          </Row>
                          <Row justifyStart>
                            <Span
                              className="mx-0"
                              fontWeight="500"
                              color={cinzaKuppi10}
                              fontSize={px2vw(11)}
                              cursor="pointer"
                            >
                              {address.structured_formatting.secondary_text}
                            </Span>
                          </Row>
                        </Col>
                      </Row>
                    </>
                  ))}
              </Col>

              <Col className="mt-1">
                {addresses.length > 0 &&
                  autocompleteInput === '' &&
                  addresses.map((address, idx) => (
                    <Row justifyStart key={idx} className="mb-2">
                      <Box
                        selected={
                          address.latitude === currentAddress?.latitude &&
                          address.longitude === currentAddress?.longitude &&
                          address.id === currentAddress?.id
                        }
                        id="change"
                        onClick={() => handleChangeAddress(address)}
                      >
                        <Row justifyStart>
                          <Col height="100%" width={px2vw(18)}>
                            <Icon
                              src={require('../../../assets/images/pin/pin.png')}
                            />
                          </Col>
                          <Span
                            className="mx-2"
                            fontWeight="600"
                            color={cinzaKuppi10}
                            fontSize={px2vw(10)}
                          >
                            {formatAddress(address)}
                          </Span>
                          {!address.id && (
                            <Icon
                              src={require('../../../assets/images/cancel/cancel.png')}
                              className="ml-auto"
                              id="remove"
                              onClick={() => handleDeleteAddress(address)}
                            />
                          )}
                        </Row>
                        <Row justifyStart padleft={px2vw(21)}>
                          <Span
                            className="ml-2 mt-3"
                            fontWeight="600"
                            color={cinzaKuppi10}
                            fontSize={px2vw(10)}
                          >
                            Endereço salvo
                          </Span>
                        </Row>
                      </Box>
                    </Row>
                  ))}
              </Col>
            </>
          ) : isAddingNewAddress ? (
            <>
              <Row justifyStart className="pb-1">
                <Span fontSize={px2vw(14)} color={cinzaKuppi10}>
                  Localidade
                </Span>
              </Row>
              <Row justifyStart>
                <Select
                  w="100%"
                  onChange={(e) => setSelectedNeighborhood(e.target.value)}
                >
                  <Option value="" default>
                    Selecione sua localidade
                  </Option>
                  {neighborhoods.length > 0 &&
                    neighborhoods?.map((neighborhood) => (
                      <Option
                        value={neighborhood.value}
                        key={neighborhood.value}
                      >
                        {neighborhood.label}
                      </Option>
                    ))}
                </Select>
              </Row>
              <Row spaceBetween className="mt-3">
                <Col width="46%">
                  <Row justifyStart>
                    <Span fontSize={px2vw(14)} color={cinzaKuppi10}>
                      Estado
                    </Span>
                  </Row>
                  <Row justifyStart>
                    <Select
                      w="100%"
                      value={selectedNeighborhoodAreaConfig?.district?.uf ?? ''}
                      disabled
                    >
                      <Option default>Seu Estado</Option>
                      {ufs.length > 0 &&
                        ufs?.map((uf) => (
                          <Option key={uf.id} value={uf.sigla}>
                            {uf.nome}
                          </Option>
                        ))}
                    </Select>
                  </Row>
                </Col>
                <Col width="46%">
                  <Row justifyStart width="40%">
                    <Span fontSize={px2vw(14)} color={cinzaKuppi10}>
                      Cidade
                    </Span>
                  </Row>
                  <Row justifyStart>
                    <Input
                      placeholder="Preencha a cidade"
                      w="100%"
                      value={cityData}
                      onChange={(e) => setCityData(e.target.value)}
                      disabled
                    />
                  </Row>
                </Col>
              </Row>
              <Row justifyStart className="pb-1 mt-3">
                <Span fontSize={px2vw(14)} color={cinzaKuppi10}>
                  Rua
                </Span>
              </Row>
              <Row justifyStart>
                <Input
                  placeholder="Ex: Rua Antonio Cabral"
                  w="100%"
                  value={streetData}
                  onChange={(e) => setStreetData(e.target.value)}
                  disabled={
                    !selectedNeighborhoodAreaConfig && !selectedNeighborhood
                  }
                />
              </Row>
              <Row spaceBetween className="mt-3">
                <Col width="23%">
                  <Row justifyStart>
                    <Span fontSize={px2vw(14)} color={cinzaKuppi10}>
                      Número
                    </Span>
                  </Row>
                  <Row justifyStart>
                    <Input
                      placeholder="Ex: 345"
                      w="100%"
                      value={houseNumber}
                      onChange={(e) => {
                        if (houseNumber?.length < 10) {
                          setHouseNumber(e.target.value)
                        }
                      }}
                      maxLength="10"
                      disabled={
                        !selectedNeighborhoodAreaConfig && !selectedNeighborhood
                      }
                    />
                  </Row>
                </Col>
                <Col width="66%">
                  <Row justifyStart>
                    <Span fontSize={px2vw(14)} color={cinzaKuppi10}>
                      Complemento
                    </Span>
                  </Row>
                  <Row justifyStart>
                    <Input
                      placeholder="Ex: Apartamento, bloco ou ponto..."
                      w="100%"
                      value={complementData}
                      onChange={(e) => {
                        if (complementData?.length < 60) {
                          setComplementData(e.target.value)
                        }
                      }}
                      MaxLength="60"
                      disabled={
                        !selectedNeighborhoodAreaConfig && !selectedNeighborhood
                      }
                    />
                  </Row>
                </Col>
              </Row>
            </>
          ) : (
            <Col className="mt-1">
              <Row justifyStart className="mb-2">
                <Box
                  id="change"
                  onClick={() => {
                    setIsAddingNewAddress(true);
                    setCurrentAddress(null);
                  }}
                >
                  <Row justifyStart>
                    <Col height="100%" width={px2vw(18)}>
                      <Icon
                        src={require('../../../assets/images/pin/pin.png')}
                      />
                    </Col>
                    <Span
                      className="mx-2"
                      fontWeight="600"
                      color={cinzaKuppi10}
                      fontSize={px2vw(15)}
                    >
                      Adicionar novo endereço
                    </Span>
                  </Row>
                  <Row justifyStart padleft={px2vw(21)}>
                    <Span
                      className="ml-2 mt-3"
                      fontWeight="600"
                      color={cinzaKuppi10}
                      fontSize={px2vw(10)}
                    >
                      Clique para adicionar um novo endereço
                    </Span>
                  </Row>
                </Box>
              </Row>
              {addresses.length > 0 &&
                autocompleteInput === '' &&
                addresses.map((address, idx) => (
                  <Row justifyStart key={idx} className="mb-2">
                    <Box
                      selected={
                        address.latitude === currentAddress?.latitude &&
                        address.longitude === currentAddress?.longitude &&
                        address.id === currentAddress?.id
                      }
                      id="change"
                      onClick={() =>
                        handleChangeAddress(
                          address,
                          false,
                          isUsingNeighborhoodArea
                        )
                      }
                    >
                      <Row justifyStart>
                        <Col height="100%" width={px2vw(18)}>
                          <Icon
                            src={require('../../../assets/images/pin/pin.png')}
                          />
                        </Col>
                        <Span
                          className="mx-2"
                          fontWeight="600"
                          color={cinzaKuppi10}
                          fontSize={px2vw(10)}
                        >
                          {formatAddress(address)}
                        </Span>
                        {!address.id && (
                          <Icon
                            src={require('../../../assets/images/cancel/cancel.png')}
                            className="ml-auto"
                            id="remove"
                            onClick={() => handleDeleteAddress(address)}
                          />
                        )}
                      </Row>
                      <Row justifyStart padleft={px2vw(21)}>
                        <Span
                          className="ml-2 mt-3"
                          fontWeight="600"
                          color={cinzaKuppi10}
                          fontSize={px2vw(10)}
                        >
                          Endereço salvo
                        </Span>
                      </Row>
                    </Box>
                  </Row>
                ))}
            </Col>
          )}
        </Col>
      </Container>
      {isAddressBoxVisible ? (
        <AddressBox className="px-5 py-2">
          <Col>
            <Row justifyCenter className="position-relative mb-4 mt-2">
              <Span
                fontSize={px2vw(13.5)}
                fontWeight="500"
                color={cinzaKuppi10}
                className="mx-auto"
              >
                Confirme o endereço
              </Span>
              <Icon
                className="mx-0 position-absolute"
                src={require('../../../assets/images/close/close.png')}
                onClick={() => setIsAddressBoxVisible(!isAddressBoxVisible)}
                style={{ right: 0 }}
              />
            </Row>
            <Row className="my-1">
              <Span
                className="mx-0"
                fontWeight="700"
                color={cinzaKuppi10}
                fontSize={px2vw(12)}
                cursor="pointer"
              >
                {selectedPrediction.structured_formatting.main_text}
              </Span>
            </Row>
            <Row className="mb-4">
              <Span
                className="mx-0"
                fontWeight="500"
                color={cinzaKuppi10}
                fontSize={px2vw(12)}
                cursor="pointer"
              >
                {selectedPrediction.structured_formatting.secondary_text}
              </Span>
            </Row>

            <Row justifyStart className="mb-4">
              <Col width="50%" className="mr-3">
                <Row justifyStart>
                  <Span fontSize={px2vw(13)} color={cinzaKuppi10}>
                    Número
                  </Span>
                </Row>
                <Row justifyStart>
                  <Input
                    placeholder="Ex: 345"
                    w="100%"
                    h={px2vw(38)}
                    value={houseNumber}
                    onChange={(e) => {
                      if (houseNumber?.length < 10) {
                        setHouseNumber(e.target.value)
                      }
                    }}
                    maxLength="10"
                  />
                </Row>
              </Col>
              <Col width="50%">
                <Row justifyStart>
                  <Span fontSize={px2vw(13)} color={cinzaKuppi10}>
                    Complemento
                  </Span>
                </Row>
                <Row justifyStart>
                  <Input
                    placeholder="Complemento"
                    w="100%"
                    h={px2vw(38)}
                    value={complementData}
                    onChange={(e) => {
                      if (complementData?.length < 60) {
                        setComplementData(e.target.value)
                      }
                    }}
                    MaxLength="60"
                  />
                </Row>
              </Col>
            </Row>
            <Row className="mt-3 mb-2">
              <ButtonPrimary
                className="mx-0"
                onClick={() =>
                  onSubmitSelectedPrediction({
                    number: houseNumber,
                    complement: complementData,
                  })
                }
                width="100%"
                height={px2vw(31)}
                color={verdeKuppi2}
                fontColor={branco}
              >
                Salvar Endereço
              </ButtonPrimary>
            </Row>
          </Col>
        </AddressBox>
      ) : (
        <ModalFooter>
          <ButtonPrimary
            onClick={handleSaveAdress}
            disabled={
              (deliveryTakeawayConfig.is_using_range_area && global?.manualOrder?.address === null) ?
                true
              : (deliveryTakeawayConfig.is_using_neighborhood_area && isAddingNewAddress && streetData.length <= 2) ?
                true
              : (deliveryTakeawayConfig.is_using_neighborhood_area && !isAddingNewAddress && global?.manualOrder?.address === null) ?
                true
              : false
            }
          >
            Confirmar
          </ButtonPrimary>
        </ModalFooter>
      )}
    </>
  );
}
